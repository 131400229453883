import packageJson from '../../../package.json'

export const config = {
  APP_VERSION: packageJson.version,
  APP_NAME: packageJson.name,
  APP_RELEASE: `${packageJson.name}@${packageJson.version}`,
}

export const features = {
  disableContactType: false,
  dailyTargetPrice: 740, // (agenda) 740€ par jour
  agendaTargetPrice: true, // (agenda) afficher le total de la journée
}
