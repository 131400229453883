import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(
  (theme) => {
    return {
      root: {
        padding: '1em',
        // material-ui skin for full calendar
        fontFamily: theme.typography.fontFamily,
        color: theme.palette.text.primary,
        '& .fc-toolbar-title': {
          fontSize: theme.typography.h5.fontSize,
          fontWeight: theme.typography.h5.fontWeight,
        },
        '& .fc-button': {
          backgroundColor: theme.palette.background.paper,
          color: theme.palette.action.active,
          borderColor: theme.palette.divider,
          fontSize: theme.typography.button.fontSize,
          fontWeight: theme.typography.button.fontWeight,
          textTransform: theme.typography.button.textTransform,
        },
        '& .fc-button-primary:disabled': {
          borderColor: theme.palette.divider,
          backgroundColor: theme.palette.action.selected,
          '&:hover': {
            backgroundColor: theme.palette.action.hover,
          },
          color: theme.palette.action.active,
        },
        '& .fc-timegrid-slot': {
          height: '35px',
        },
        '& .fc-button-group .fc-button': {
          borderColor: theme.palette.divider,
          color: theme.palette.action.active,
        },
        '& .fc-button:hover': {
          borderColor: theme.palette.divider,
          backgroundColor: theme.palette.action.hover,
          color: theme.palette.text.primary,
        },
        '& .fc-button-primary:not(:disabled):active': {
          backgroundColor: theme.palette.action.active,
          color: theme.palette.text.primary,
        },
        '& .fc-button-group .fc-button-primary:not(:disabled):active': {
          borderColor: theme.palette.divider,
        },
        '& .fc-button-primary:not(:disabled).fc-button-active': {
          backgroundColor: theme.palette.action.selected,
          '&:hover': {
            backgroundColor: theme.palette.action.hover,
          },
          color: theme.palette.action.active,
        },
        '& .fc-button-group .fc-button-primary:not(:disabled).fc-button-active':
          {
            borderColor: theme.palette.divider,
            color: theme.palette.text.secondary,
          },
        '& .fc-button-primary:not(:disabled):focus': {
          boxShadow: 'none',
        },
        '& .fc-button-primary:not(:disabled):active:focus': {
          boxShadow: 'none',
        },
        '& .fc-button-primary:not(:disabled).fc-button-active:focus': {
          boxShadow: 'none',
        },
        '& .fc-popover': {
          backgroundColor: theme.palette.background.paper,
        },
      },
      dayHeaderGreen: {
        backgroundColor: theme.palette.primary.light,
        color: theme.palette.background.paper,
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        paddingTop: theme.spacing(0.5),
        paddingBottom: theme.spacing(0.5),
        borderRadius: '4px',
      },
      dayHeaderRed: {
        backgroundColor: theme.palette.error.main,
        color: theme.palette.background.paper,
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        paddingTop: theme.spacing(0.5),
        paddingBottom: theme.spacing(0.5),
        borderRadius: '4px',
      },
    }
  },
  {
    name: 'RaCalendar',
  },
)
